





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.circularStrategy {
	.content {
		padding: 0.25rem;

		.csTitle {
			color: #373737;
			font-size: 0.32rem;
			// font-family: Bahnschrift;
			font-weight: bold;
			height: 0.63rem;
			display: flex;
			align-items: center;
			padding-bottom: 0.25rem;
			margin-bottom: 0.2rem;

			span {
				color: #90c4ff;
				font-size: 0.2rem;
				padding: 0 0.1rem;
				line-height: 0.36rem;
				background: #f1f7ff;
				border-radius: 0.04rem;
				display: inline-block;
				margin-left: 0.1rem;
				&.gray {
					color: #9b9b9b;
					background: #f5f5f5;
				}
				&.gray1 {
					color: #de5841;
					background: rgba(222, 88, 65, 0.09);
				}
				&.gray2 {
					color: #4daa90;
					background: rgba(77, 170, 144, 0.09);
				}
			}
		}
	}

	.strategy {
		.strategyItem {
			padding: 0.1rem 0.2rem;
			margin-bottom: 0.1rem;
			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0.34rem;
			}
		}

		.xian {
			padding: 0.01rem 0.2rem;
			border-top: 0.01rem solid #f5f5f5;
		}

		.strategyItem1 {
			padding: 0.28rem 0.2rem 0 0.2rem;
			// margin-bottom: .1rem;
			&:last-child {
			}
		}

		.span1 {
			font-size: 0.24rem;
			color: #666666;
			margin-right: 0.12rem;
		}

		.span2 {
			font-size: 0.28rem;
			font-family: Bahnschrift;
			font-weight: 600;
			color: #373737;
		}
	}

	.action {
		// padding-bottom: 1rem;

		.actionItem {
			// margin-top: 0.55rem;
			padding-top: 0.1rem;
			text-align: center;

			img {
				height: 0.6rem;
				display: block;
				margin: auto;
			}

			p {
				margin-top: 0.12rem;
				font-size: 0.24rem;
				color: #666666;
			}
		}

		.actionButton {
			height: 0.79rem;
			width: 69%;
			margin: 1rem auto 0;
			background: #ff4b04;
			border-radius: 100px;
			font-size: 0.31rem;
			font-weight: bold;
			color: #ffffff;
			line-height: 0.78rem;
			text-align: center;
		}
	}
}

// ------------20200412[start]----------------
.err_msg {
	width: 100%;
	height: 0.4rem;
	line-height: 0.4rem;
	background-color: rgba(245, 70, 93, 0.1);
	color: #f5465d;
	font-size: 0.24rem;
	text-align: center;
}
.msg_list {
	padding: 0.25rem 0.3rem;
}
.layoutBox {
	min-height: calc(100vh - 1.76rem);
}
.head_list {
	display: flex;
	justify-content: space-between;
	.numItem {
		width: 33.3%;
		text-align: center;
		margin-bottom: 0.5rem;
		p {
			color: #666666;
			font-size: 0.24rem;
			margin-bottom: 0.1rem;
		}

		span {
			color: #373737;
			font-family: Bahnschrift;
			font-size: 0.28rem;
		}
	}
	.numItem1 {
		width: 33.3%;
		text-align: center;
		p {
			color: #666666;
			font-size: 0.24rem;
			margin-bottom: 0.1rem;
		}

		span {
			color: #373737;
			font-family: Bahnschrift;
			font-size: 0.28rem;
		}
	}
}
.csTitle1 {
	margin-top: 0.25rem;
	border-top: 0.01rem solid rgba(211, 217, 222, 0.5);
	padding: 0.2rem 0 0 0;
	color: #333333;
	font-size: 0.28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	span {
		font-weight: bold;
	}
}
// ------------20200412[end]----------------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;